import React from "react"
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import Layout from "../components/layout"
import Section from "../components/section"
import SEOComponent from "../components/seo"


const PrivacyContent = styled('div')`
    max-width: 800px;
    margin: 0 auto;
    color: #fff !important;
    padding: 15px;
    .head {
        font-family: HarabaraMais-Bold;
        font-size: 225%;
        color: #fff;
        line-height: 1;
        margin-bottom: 15px;
    }
    .text {
        font-size: 100%;
        line-height: 20px;
        font-family: SFUIText-Light;
    }
    .sub-head {
        padding-top: 25px;
        font-size: 150%;
        color: #fff;
        line-height: 1;
        font-family: SFUIText-Regular;
        padding-bottom: 25px;
    }
    .list-box ul {
        list-style: none;
        li {
            &::before {
                content: "•";
                color: #fff;
                display: inline-block;
                width: 0;
                margin-left: -24px;
                margin-top: -2px;
                position: absolute;
                font-size: 19px;
            }
            margin-bottom: 15px;
        }
    }
`

function PrivacyPage() {
  return(
    <Layout
      footerType='normal'
    >
      <SEOComponent title="Desynova | Privacy Policy" />
      <Section style={{
        height: 'auto',
      }}>
        <PrivacyContent>
            <Fade bottom>
                <div class="page-title">
                    <div class="head"> Privacy Policy </div>
                    <div class="text">
                        We deeply respect your privacy &amp; all your data. We have built our services with a strong set of privacy principles in
                        mind. The following key points are valid for all our platforms &amp; products unless explicitly mentioned
                        otherwise.
                    </div>
                </div>
                <div class="page-body">
                    <div class="sub-head">What do we do with all the information </div>
                    <div class="list-box">
                        <ul>
                            <li>
                                <div class="text">
                                    We collect information such as text & audio in the form of comments & replies & other related actions, information related
                                    to hardware/OS used to access our apps/websites, user location & IPs.
                                </div>
                            </li>
                            <li>
                                <div class="text">
                                    User actions related to the usage of our products & other related actions may also be collected to better understand & improve
                                    the User Experience.
                                </div>
                            </li>
                            <li>
                                <div class="text">
                                    We do not in any way read/decipher this information, nor intend to, unless explicitly specified.
                                </div>
                            </li>
                            <li>
                                <div class="text">
                                    We use cookies to operate & provide our Services, including to provide our Services that are web-based, app-based, improve
                                    your experiences, understand how our services are being used, and customise our Services.
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="sub-head">What do we do with all the information </div>
                    <div class="list-box">
                        <ul>
                            <li>
                                <div class="text">
                                    We will continue to collect the above mentioned information till you or your affiliated organisation is actively subscribed
                                    to one or all of our products.
                                </div>
                            </li>
                            <li>
                                <div class="text">
                                    Once you permanently stop using our services, either individually or collectively as part of your organisation, all your
                                    user related information will be deleted from our databases.
                                </div>
                            </li>
                            <li>
                                <div class="text">
                                    Post deletion of your account, historic data related to user behaviour analytics may still be maintained with us.
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="sub-head">Have more queries regarding your data? </div>
                    <div class="list-box">
                        <ul>
                            <li>
                                <div class="text">
                                    Our Customer Support is available at all times via any of our platforms. Please feel free to reach out & share your concerns.
                                    We will more than happy to help & be transparent about it.
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </Fade>
        </PrivacyContent>
      </Section>
    </Layout>
  )
}

export default PrivacyPage
